import React from 'react'

const BLM = () => {
  return (
    <div className="black-lives-matter">
      <h1>Black Lives Matter</h1>
    </div>
  )
}

export default BLM
